$navBarHeight:80px;
$footerHeight:115px;
$bannerHeight:35vh;

.pageContainer {
  background-color: white;
  min-height: calc(100vh - #{$navBarHeight} - #{$footerHeight} - #{$bannerHeight});
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  height: $footerHeight;
  background-color: rgb(121,198,192);
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  a {
    border-radius: 50%;
    margin-right: 20px;
    height: 60px;
    img {
      height: 100%;
    }

    &:hover {
      -webkit-box-shadow: 5px 5px 15px 5px #446896; 
      box-shadow: 5px 5px 15px 5px #446896;

    }
  }
}