.page {
  .textContainer {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 20px;
    padding: 15px;
  }

  h4 {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
    padding: 10px;
  }

  .center {
    display: block;
    margin: 0 auto;
  }

  .inline {
    display: inline;
  }
}