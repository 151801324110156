@import '../../styles/variables.scss';

.thumbnails {
  font-family: "Lilita One";
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  .thumbnail {
    display: inline-flex;
    height: 200px;
    width: 300px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    &:hover {
      box-shadow: 1px 1px 8px 9px rgba(148, 218, 218, 0.65);
      -webkit-box-shadow: 1px 1px 8px 9px rgba(150,197,197,0.65);
      -moz-box-shadow: 1px 1px 8px 9px rgba(150,197,197,0.65);

    }
    
    a {
      box-sizing: border-box;
      vertical-align: bottom;
      height: 100%;
      width: 100%;
      padding: 175px 10px 5px 10px ;
      background: rgb(121,198,192);
      background: linear-gradient(0deg, rgba(121,198,192,1) 17%, rgba(121,198,192,0.6026785714285714) 37%, rgba(121,198,192,0) 65%);
      border-radius: 5px;
      text-decoration: none;
      color: black;

      &:visited {
        color: black;
      }
    }
}
}