@import '../../styles/variables.scss';

.home {
  padding-bottom: 20px;

  .home_featured_articles {
    text-align: center;
    padding-top: 17px;
    padding-bottom: 10px;
    width: 80%;
    margin: 0 auto;
    font-size: 30px;
    font-family: "Lilita One";
    border-bottom: 4px solid $primaryBlue;
    margin-bottom: 5px;
  }

  .banner_title {
    span {
      min-width: 157px;
      display: inline-block;
      text-align: center;
      margin-right: 5px;
    }
  }

  .home_description {
    text-align: center;
    padding-top: 15px;
    max-width: 1100px;
    margin: 0 auto;
    font-size: 20px;
  }
}